import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { AlertContext, SettingsContext, ShopContext, UserContext } from "../App";
import Label from "./Label";
import api from "./api/api";

export default function GoogleLoginButton2({ setUserPopupVisible, style }) {
	const { settings, lang } = useContext(SettingsContext);
	const { load_customer } = useContext(UserContext);
	const { setAlertData } = useContext(AlertContext);
	const { shop } = useContext(ShopContext);

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => handleSuccess(tokenResponse),
	});

	const handleSuccess = async (responseGoogle) => {
		console.log("handleSuccess");
		console.log(responseGoogle);

		const user = await fetch(
			"https://www.googleapis.com/oauth2/v1/userinfo?access_token=" +
				responseGoogle.access_token,
			{
				method: "GET",
				headers: {
					Authorization: responseGoogle.access_token,
				},
			}
		);
		const decoded_user = await user.json();
		console.log(decoded_user);

		const data = {
			name: decoded_user.given_name,
			surname: decoded_user.family_name,
			email: decoded_user.email,
			id: decoded_user.id,
			shop_id: shop.id,
			customer_lang: lang,
		};

		const response = await api.post("/customers/google_login/", data);
		console.log(response);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			localStorage.setItem("customer_id", response.data.id);
			load_customer(response.data.id);
			setUserPopupVisible(false);
		}
	};

	const handleFailure = (response) => {
		console.log("handleFailure");
		console.log(response);
		setAlertData({
			message: <Label number={100} />,
			message: <Label number={101} />,
			confirmMessage: <Label number={31} />,
		});
	};

	return (
		<button className="button mb-3" type="button" onClick={() => login()} style={style}>
			Sign in with Google
		</button>
	);
}
