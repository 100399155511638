import React, { useContext, useEffect, useState } from "react";
import { SettingsContext, CartContext, CategoriesContext } from "../App";
import Products from "./Products";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";
import Categories from "./Categories";
import Courses from "./Courses";
import ProductsOnePage from "./ProductsOnePage";
import Responsive from "./Responsive.js";
import Label from "./Label.js";
import CategoriesTags from "./CategoriesTags.js";
import { textLabel } from "./functions/functions.js";

function Page() {
	const { settings, course, setCourse, category, setCategory, initTablePayment, labels, lang } =
		useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);
	const { cart } = useContext(CartContext);
	const [menuVisible, setMenuVisible] = useState(false);
	const [src, setSrc] = useState("");
	const [srcProducts, setSrcProducts] = useState([]);

	let sclollTimeOut;

	useEffect(() => {
		filter();
	}, [src]);

	useEffect(() => {
		document.getElementById("main").addEventListener("scroll", handleScroll);

		return () => {
			document.getElementById("main")?.removeEventListener("scroll", handleScroll);
		};
	}, [categories, category]);

	function filter() {
		console.log("filter");
		let arr = [];
		if (src) {
			categories.map((course) =>
				course.categories.map((category) =>
					category.products.map((product) => {
						//console.log(product.name, src, product.name.includes(src));
						if (product.name.toLowerCase().includes(src.toLowerCase()))
							arr.push(product);
					})
				)
			);
		}
		arr.sort(function (a, b) {
			return a["name"] > b["name"] ? 1 : -1;
		});
		setSrcProducts(arr);
	}

	const handleScroll = (e) => {
		clearTimeout(sclollTimeOut);
		sclollTimeOut = setTimeout(() => {
			if (settings.selfordering.categories_menu_type == 3) {
				console.log("handleScroll");
				let visibleCategory = false;
				for (const categoryBox of document.getElementsByClassName("category-box")) {
					const rect = categoryBox.getBoundingClientRect();
					if (rect.top < getContainerBottom() && rect.bottom > getContainerTop()) {
						visibleCategory = categoryBox;
						break;
					}
				}
				console.log(visibleCategory.id);
				if (visibleCategory) {
					console.log("change category by scroll to", visibleCategory);
					for (const course of categories) {
						for (const category of course.categories) {
							if ("category-" + category.id == visibleCategory.id) {
								setCategory(category);
							}
						}
					}
				}
			}
		}, 100);
	};

	const getContainerTop = () => {
		const container = document.getElementById("main");
		if (container) {
			const rect = container.getBoundingClientRect();
			return rect.top;
		}
	};

	const getContainerBottom = () => {
		const container = document.getElementById("main");
		if (container) {
			const rect = container.getBoundingClientRect();
			return rect.bottom;
		}
	};

	return (
		<>
			<div className="page">
				<Header menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
				{settings.selfordering.categories_menu_type == 2 && (
					<div className="menu-categories-2">
						<div className="scroll-content">
							{categories &&
								categories.map((cr, i) =>
									cr.categories.map((c, i) => (
										<button
											key={i}
											onClick={() => {
												setCourse(cr);
												setCategory(c);
											}}
											className={
												"button " + (c.id == category.id ? "active" : "")
											}
										>
											{c.name}
											<CategoriesTags category={c} />
										</button>
									))
								)}
						</div>
					</div>
				)}
				<div className="lock-content">
					<div className="columns">
						{settings.selfordering.categories_menu_type == 3 && (
							<div className="sidebar">
								{categories.map((course, i) => (
									<div key={i}>
										{course.name && (
											<div className="course-title">{course.name}</div>
										)}
										{course.categories.map((c, n) => (
											<button
												className={category.id == c.id ? "selected" : ""}
												key={n}
												onClick={() => {
													//setCourse(course);
													if (
														settings.selfordering
															.products_loading_type == "onepage"
													) {
														const element = document.getElementById(
															"category-" + c.id
														);
														if (element) {
															element.scrollIntoView({
																behavior: "smooth",
															});
														}
													} else {
														setCategory(c);
													}
												}}
											>
												<CategoriesTags category={c} />
												{c.name}
											</button>
										))}
									</div>
								))}
							</div>
						)}
						<div
							className={
								"main " +
								(settings.selfordering.categories_menu_type == 2 ? "d-block" : "")
							}
						>
							<div className="inner-container">
								<div className="src">
									<span>
										<i className="fa fa-search"></i>
									</span>
									<input
										type="text"
										value={src}
										onChange={(e) => setSrc(e.target.value)}
										placeholder={textLabel(labels, 134, lang)}
									/>
									{src && (
										<button onClick={() => setSrc("")}>
											<i className="fas fa-times"></i>
										</button>
									)}
								</div>
								<div id="main" className="inner-content">
									{src && <Products src={srcProducts} />}
									{!src && !course && <Courses />}
									{course && !category && <Categories />}
									{!src &&
										course &&
										category &&
										settings.selfordering.products_loading_type ==
											"onepage" && <ProductsOnePage />}
									{!src &&
										course &&
										category &&
										settings.selfordering.products_loading_type !=
											"onepage" && <Products />}
								</div>
							</div>
						</div>
					</div>
					{cart && cart.is_lock == 1 && (
						<div className="internal-lock">
							<div className="internal-lock-content">
								<div className="title">
									<Label number={123} />
								</div>
								<div className="message">
									<Label number={124} />
								</div>
								<button onClick={initTablePayment}>
									<Label number={125} />
								</button>
							</div>
						</div>
					)}
				</div>
				{cart && <Footer />}
				<Responsive />
			</div>
			{menuVisible && <Menu setMenuVisible={setMenuVisible} />}
		</>
	);
}

export default Page;
